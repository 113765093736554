<template>
  <div class="content-wrapper">
    <div class="employees main-content">

      <div class="page-header">
        <h1>Employee Group</h1>
      </div>

      <div class="view-toolbars">
        <ItemFilter class="fitler" placeholder="Enter Group Name" @search="searchGroups" @reset="resetFilter">
          <el-form class="search-form" :model="searchForm">
            <div class="row">
              <el-form-item label="Group Type" style="max-width: 550px;">
                <el-select v-model="searchForm.type">
                  <el-option v-for="groupType in groupTypes" :key="groupType.id" :label="groupType.name" :value="groupType.name"></el-option>
                </el-select>
              </el-form-item>
            </div>
          </el-form>
        </ItemFilter>
        <div class="tool">
          <router-link :to="{name:'AddEmployeeGroup'}">
            <button class="btn btn-primary">
              <i class="el-icon-plus"></i>
              New
            </button>
          </router-link>
        </div>
      </div>

      <div class="employee-list">
        <table class="table table-bordered">
          <thead>
            <tr>
              <th scope="col">Group Name</th>
              <th scope="col">Group Type</th>
              <th scope="col">Action</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="employeeGroup in employeeGroups">
              <th scope="row">{{employeeGroup.name}}</th>
              <td>{{employeeGroup.type}}</td>
              <td class="action">
                <router-link :to="{name:'EmployeeGroup', params: {id: employeeGroup.id}}">View</router-link>
                <a href="#" class="delete-link" @click="deleteGroup(employeeGroup.id)">Delete</a>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
// @ is an alias to /src
import ItemFilter from '@/components/ItemFilter.vue';
import Common from '@/lib/common';
import Hr from '@/lib/hr';

export default {
  name: 'EmployeeGroups',
  components: {
    ItemFilter,
  },
  data(){
    return {
      searchForm:{
        'type': '',
      },
      groupTypes: [],
      originGroups: [],
      employeeGroups: [],
    }
  },
  mounted(){
    this.loadEmployeeGroupType();
    this.loadEmployeeGroups();
  },
  methods:{
    async loadEmployeeGroupType(){
      const loginInfo = Common.getLoginInfo();
      try{
        const groupTypes = await Hr.loadEmployeeGroupType(this.apiUrl, loginInfo);
        this.groupTypes = groupTypes;
      }catch(err){
        console.log(err);
      }
    },
    async loadEmployeeGroups(){
      const loginInfo = Common.getLoginInfo();

      try{
        const employeeGroups = await Hr.loadEmployeeGroups(this.apiUrl, loginInfo);
        this.originGroups = employeeGroups;
        this.employeeGroups = employeeGroups;
      }catch(err){
        console.log(err);
      }
    },
    searchGroups(textSearchValue){
      this.searchForm['name'] = textSearchValue;
      const searchFilter = this.searchForm;
      const newGroups = Common.filterItems(searchFilter, this.originGroups);
      this.employeeGroups = newGroups;
    },
    resetFilter(){
      //Clean Filter
      for(let i = 0; i < Object.keys(this.searchForm).length; i++){
        const searchKey = Object.keys(this.searchForm)[i];
        this.searchForm[searchKey] = '';
      }
      this.employeeGroups = this.originGroups;
    },
    async deleteGroup(groupId){
      const loginInfo = Common.getLoginInfo();
      try{
        await Hr.deleteEmployeeGroup(this.apiUrl, groupId, loginInfo);
        const newGroups = this.employeeGroups.filter(function(item) {
          return item.id !== groupId;
        });
        this.employeeGroups = newGroups;
      }catch(err){
        console.log(err);
      }
    },
  },
  computed: mapState({
   apiUrl: state => state.apiUrl,
 }),
}
</script>

<style lang="scss">
.view-toolbars{
  display: flex;
  position: relative;
  .fitler{
    flex: 1;
  }
  .el-icon-plus{
    font-size: 1.2em;
  }
}
.employee-list{
  .action{
    a{
      display: inline-block;
      margin-right: 10px;
    }
    .delete-link{
      color: red;
    }
  }
}
</style>
